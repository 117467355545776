.error-text {
  color: red;
  font-weight: 500;
  padding: 5px 12px;
  font-family: "Poppins";
  font-size: 14px;
  margin: 0;
}

.common-section {
  min-height: calc(100vh - 222px);
  height: 100%;
}

.sub-title {
  font-size: 14px;
  color: #2A3342;
  font-weight: 600;
}

.plan-validity-blk {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  padding: 14px 0px;
  gap: 5px;
  flex-wrap: wrap;
}

.plan-validity-blk span {
  font-size: 15px;
  font-family: 'Poppins';
  font-weight: 500;
  color: #ff020293;
}

.plan-validity-blk p {
  font-size: 16px;
  font-family: 'Poppins';
  font-weight: 500;
  color: #2b3241bd !important;
}

.plan-txt-blk {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.plan-txt-blk p {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 22px;
  margin: 0;
  color: #2b3241c4;
}

.link {
  word-break: break-all;
  cursor: pointer;
}

.link:hover {
  color: #0281ffb5 !important;
}

.react-tel-input .form-control {
  border: 0px !important;
  background: transparent !important;
  padding: 1px 45px !important;
  width: 100% !important;
  font-size: 18px !important;
  background-color: #f7f8f9 !important;
  font-weight: 500 !important;
  font-family: 'Poppins' !important;
  color: #7D7D7D !important;
}

.react-tel-input .form-control::placeholder {
  color: #7D7D7D;
  font-weight: 500 !important;
  font-family: 'Poppins' !important;
}

.react-tel-input .selected-flag {
  padding: 0 0 0 0 !important;
}

.react-tel-input .selected-flag:focus:before,
.react-tel-input .selected-flag.open:before {
  border-color: transparent !important;
  box-shadow: none !important;
}

.react-tel-input .form-control:focus {
  background-color: transparent !important;
  border-color: transparent !important;
  outline: 0 !important;
  box-shadow: none !important;
}

div:where(.swal2-container) h2:where(.swal2-title) {
  font-size: 1.3rem !important;
  font-family: 'SF Pro Display' !important;
}

div:where(.swal2-container) button:where(.swal2-styled):where(.swal2-confirm) {
  font-size: 15px !important;
  border-radius: 6px !important;
}

div:where(.swal2-container) button:where(.swal2-styled):where(.swal2-cancel) {
  font-size: 15px !important;
  border-radius: 6px !important;
}

.chip {
  padding: 0px 0px !important;
}

.searchWrapper {
  border: 0px !important;
  border-radius: 0px !important;
  min-height: 0px !important;
  padding: 0px !important;
  position: relative;
}

.multiSelectContainer input {
  margin-top: 0px !important;
  color: #fff !important;
  font-size: 18px !important;
  font-family: "Poppins" !important;
  color: #7D7D7D !important;
  font-weight: 500 !important;
}


.chip {
  color: #fff !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  font-family: "Poppins" !important;
  color: #7D7D7D !important;
}

.optionListContainer {
  content: "" !important;
  left: 5px !important;
  margin-top: 18px !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
  background-color: #f7f8f9 !important;
  border-radius: 20px !important;
}

.multiSelectContainer ul {
  border: 0px !important;
  padding: 18px !important;
  list-style: none !important;
}

.highlightOption {
  background: #2A3342 !important;
  color: #fff !important;
  font-family: "Poppins" !important;
  border-radius: 5px !important;
}

.multiSelectContainer li:hover {
  background: #2A3342 !important;
  font-family: "Poppins" !important;
  border-radius: 5px !important;
}

.custom-dropdown {
  position: relative;
  width: 100%;
}

.dropdown-header {
  cursor: pointer;
  font-size: 17px;
  transition: all 0.3s ease;
  font-weight: 500;
}

.dropdown-header.open {
  border-color: #007bff;
}

.dropdown-body {
  position: absolute;
  top: 55px;
  left: 0;
  width: 100%;
  background-color: white;
  border-top: none;
  max-height: 250px;
  overflow-y: auto;
  z-index: 10;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 4px 4px;
}

.search-input {
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  border: none;
  outline: none;
  font-size: 16px;
  font-family: 'Poppins';
  font-weight: 500;
  border-bottom: 1px solid #ccc;
  color: #2A3342 !important;
  position: sticky;
  top: 0;
}

.dropdown-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.dropdown-item {
  padding: 15px 20px;
  cursor: pointer;
  background-color: #fff;
  border-bottom: 1px solid #f0f0f0;
  font-size: 18px;
  font-weight: 500;
  font-family: 'Poppins';
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}

.no-results {
  padding: 10px;
  text-align: center;
  color: #888;
}

.custom-option-input {
  display: flex;
  padding: 10px;
}

.custom-option-input input {
  width: calc(100% - 60px);
  padding: 8px 20px;
  border-radius: 4px;
  margin-right: 10px;
  background: #f7f8f9;
  border: 0px;
  font-size: 15px;
  font-family: 'SF Pro Display';
  font-weight: 500;
}

.custom-option-input input:focus-visible {
  outline: none;
}

.custom-option-input button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background-color: #2b3241;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
}

.custom-option-input button:hover {
  background-color: #2b3241;
}

/* Default Bootstrap classes handle small to medium screens */
.col-lg-custom {
  flex: 0 0 29%;
  /* Custom width for large screens (roughly 3.5 out of 12 columns) */
  max-width: 29%;
}

.not-found-text {
  height: 15vh;
  align-items: center;
  justify-content: center;
  display: flex;
}

.not-found-text p {
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: 500;
  color: #2b3241;
}

.top-icon-blk {
  font-size: 12px;
  font-weight: bold;
  position: absolute;
  top: -15px;
  left: 90%;
  z-index: 1;
  color: #fb2024;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  height: 20px;
  padding: 2px 5px;
  background: #edbbbb;
  border-radius: 4px;
}


.notify-icon-blk {
  background: red;
  border-radius: 100%;
  font-size: 10px;
  font-weight: bold;
  position: absolute;
  top: -10px;
  right: -12px;
  z-index: 100;
  color: white;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
}

li.nav-item {
  cursor: pointer;
}

.verify-icon {
  position: absolute;
  content: "";
  right: 30px;
  top: 12px;
  z-index: 2;
  display: flex;
  gap: 2px;
}

.verify-icon p {
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 500;
  color: #2A3342;
}

.verify-icon img {
  width: 25px;
  height: 25px;
}

.input-label {
  font-size: 18px;
  font-weight: 500;
  font-family: var(--pp-font);
  color: #7D7D7D !important;
}

.location {
  cursor: pointer !important;
}

.location:hover {
  color: #1abdeb !important;
}

.note-text-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.note-text-inner {
  display: flex;
  gap: 5px;
  flex-direction: column;
  text-align: center;
  padding: 20px;
  border-radius: 1px;
  background: -webkit-linear-gradient(#15d7e7c7, #ccf9ff);
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
  min-width: 500px;
  /* animation: scale 2s infinite forwards; */
  /* transition: all 2s ease-in-out; */
}

/* @keyframes scale {

  0%,
  100% {
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
    transform: scale(1.0);
  }

  50% {
    box-shadow: 0px 0px 15px rgba(64, 191, 255, 0.45);
    transform: scale(1.05);
  }
} */

.note-text-inner p {
  margin-bottom: 0px;
  font-size: 18px;
  color: #2b3241;
  font-weight: 500;
  font-family: 'SF Pro Display';
}

.note-text-inner h2 {
  margin-bottom: 0px;
  font-size: 18px;
  font-weight: 500;
  font-family: 'Poppins';
  color: #2c3140;
}

.note-text-inner span {
  font-size: 16px;
  font-weight: 500;
  font-family: 'SF Pro Display';
}

/* .flash-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: -webkit-linear-gradient(#15d7e7c7, #18e1fc);
  color: #000;
  padding: 14px 15px;
  border-radius: 1px;
  animation: blink 4s linear infinite;
  position: fixed;
  top: 120px;
  right: 20px;
  z-index: 1000;
  min-width: 300px;
}

.flash-message h2 {
  margin-bottom: 0px;
  font-size: 18px;
  font-weight: 500;
  font-family: 'Poppins';
  color: #2c3140;
}

.flash-message span {
  font-size: 18px;
  font-weight: 500;
  font-family: 'SF Pro Display';
  color: red;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

.close-btn {
  background: none;
  border: none;
  color: #000;
  font-size: 18px;
  cursor: pointer !important;
  position: absolute;
  right: -10px;
  top: -14px;
}

.close-btn:hover {
  color: #ff0000;
} */


.location-fetch-text {
  margin-bottom: 0px;
  font-size: 15px;
  margin-left: 15px;
  margin-top: 10px;
  color: #2b3241;
  font-weight: 500;
  font-family: var(--pp-font);
}


/* Ensure it wraps when screen is medium (≥ 768px and < 992px) */
@media (max-width: 991.98px) {
  .col-lg-custom {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

/* Ensure it wraps when screen is small (≥ 576px and < 768px) */
@media (max-width: 767.98px) {
  .col-lg-custom {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .verify-icon img {
    width: 20px;
    height: 20px;
  }

  .verify-icon p {
    font-size: 12px;
  }

  .verify-icon {
    right: 10px;
    top: 5px;
  }
}